<template>
	<div>
		<ContentHeader title="Laporan Akuntansi" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
          <div class="card-header">
            <h3 class="card-title"><div style="#007bff">Daftar Laporan</div></h3>
          </div>
          <div class="card-body">
          	<div class="row">
        		  <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
        						<router-link to="/laporan-jurnal-keuangan" class="cursor-pointer">
		                	<h3 class="card-title">Laporan Jurnal Keuangan</h3>
        						</router-link>		
		              </div>
		            </div>
		          </div>		
		          <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/laporan-buku-besar" class="cursor-pointer">
		                	<h3 class="card-title">Laporan Buku Besar</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		          <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/laporan-neraca" class="cursor-pointer">
		                	<h3 class="card-title">Laporan Neraca</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		          <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/laporan-laba-rugi" class="cursor-pointer">
		                	<h3 class="card-title">Laporan Laba Rugi</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		        </div>
		        <div class="row">
		          <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/laporan-perubahan-modal" class="cursor-pointer">
		                	<h3 class="card-title">Laporan Perubahan Modal</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		          <div class="col-md-3">
		            <div class="card card-outline card-primary">
		              <div class="card-header">
		              	<router-link to="/laporan-budgeting" class="cursor-pointer">
		                	<h3 class="card-title">Laporan Budgeting</h3>
        						</router-link>
		              </div>
		            </div>
		          </div>
		        </div>
          </div>
      	</div>
			</div>
		</div>
	</div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'

export default {
	components: {
		ContentHeader
	}
}
</script>